import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=01183faa&scoped=true&"
import script from "./dashboard.vue?vue&type=script&lang=ts&"
export * from "./dashboard.vue?vue&type=script&lang=ts&"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=01183faa&prod&lang=scss&scoped=true&"
import style1 from "./dashboard.vue?vue&type=style&index=1&id=01183faa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01183faa",
  null
  
)

export default component.exports