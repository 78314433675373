
import Vue, { PropType } from 'vue';
import Application from '../models/applications';
import CreateEditView from '@shell/mixins/create-edit-view';
import CruResource from '@shell/components/CruResource.vue';
import ResourceTabs from '@shell/components/form/ResourceTabs/index.vue';
import Tab from '@shell/components/Tabbed/Tab.vue';
import Loading from '@shell/components/Loading.vue';
import AppInfo, { EpinioAppInfo } from '../components/application/AppInfo.vue';
import AppConfiguration, { EpinioAppBindings } from '../components/application/AppConfiguration.vue';
import { epinioExceptionToErrorsArray } from '../utils/errors';
import Wizard from '@shell/components/Wizard.vue';
import { createEpinioRoute } from '../utils/custom-routing';
import AppSource from '../components/application/AppSource.vue';
import { _EDIT } from '@shell/config/query-params';

import AppProgress from '../components/application/AppProgress.vue';
import { EpinioAppSource, EpinioCompRecord, EPINIO_TYPES } from '../types';
import { allHash } from '@shell/utils/promise';

interface Data {
  bindings: EpinioAppBindings,
  source?: EpinioAppSource,
  errors: string[],
}

// Data, Methods, Computed, Props
export default Vue.extend<Data, EpinioCompRecord, EpinioCompRecord, EpinioCompRecord>({
  components: {
    AppSource,
    AppProgress,
    Loading,
    CruResource,
    ResourceTabs,
    Tab,
    AppInfo,
    Wizard,
    AppConfiguration
  },

  data() {
    const source = this.value.appSource;

    /**
     * Edit git application from specific commit
     */
    if (source.git && this.$route.query?.commit) {
      source.git.commit = this.$route?.query?.commit;
    }

    return {
      bindings: {
        configurations: [],
        services:       []
      },
      errors: [],
      source,
      steps:  [
        {
          name:       'source',
          label:      this.t('epinio.applications.steps.source.label'),
          subtext:    this.t('epinio.applications.steps.source.subtext'),
          ready:      false,
          nextButton: {
            labelKey: 'epinio.applications.steps.configurations.update',
            style:    'btn role-primary bg-warning'
          }
        }, {
          name:           'progress',
          label:          this.t('epinio.applications.steps.progress.label'),
          subtext:        this.t('epinio.applications.steps.progress.subtext'),
          ready:          false,
          previousButton: { disable: true }
        },
      ],
      epinioInfo: undefined,
      tabErrors:  { appInfo: false }
    };
  },

  mixins: [CreateEditView],

  props: {
    value: {
      type:     Object as PropType<Application>,
      required: true
    },
    initialValue: {
      type:     Object as PropType<Application>,
      required: true
    },
    mode: {
      type:     String,
      required: true
    },
  },

  async fetch() {
    const hash: { [key:string]: any } = await allHash({
      ns:     this.$store.dispatch('epinio/findAll', { type: EPINIO_TYPES.NAMESPACE }),
      charts: this.$store.dispatch('epinio/findAll', { type: EPINIO_TYPES.APP_CHARTS }),
      info:   this.$store.dispatch('epinio/info'),
    });

    this.epinioInfo = hash.info;
  },

  computed: {
    shouldShowButtons() {
      return this.$route.hash === '#source' ? 'hide-buttons-deploy' : '';
    },
    showSourceTab() {
      return this.mode === _EDIT;
    },
    validationPassed() {
      return !Object.values(this.tabErrors).find((error) => error);
    }
  },

  methods: {
    async save(saveCb: (success: boolean) => void) {
      this.errors = [];
      try {
        await this.value.update();

        await this.value.updateConfigurations(
          this.initialValue.baseConfigurationsNames || [],
          this.bindings?.configurations || [],
        );

        await this.value.updateServices(
          this.initialValue.services || [],
          this.bindings?.services || [],
        );

        await this.value.forceFetch();
        if (!this._isBeingDestroyed || !this._isDestroyed) {
          saveCb(true);
          this.done();
        }
      } catch (err) {
        this.errors = epinioExceptionToErrorsArray(err);
        saveCb(false);
      }
    },

    set(obj: { [key: string]: string}, changes: { [key: string]: string}) {
      Object.entries(changes).forEach(([key, value]: [string, any]) => {
        Vue.set(obj, key, value);
      });
    },

    updateInfo(changes: EpinioAppInfo) {
      this.value.meta = this.value.meta || {};
      this.value.configuration = this.value.configuration || {};
      this.set(this.value.meta, changes.meta);
      this.set(this.value.configuration, changes.configuration);
    },

    updateConfigurations(changes: EpinioAppBindings) {
      Vue.set(this, 'bindings', changes);
      this.set(this.value.configuration, [
        ...changes.configurations,
        // .map(s => s.meta.name)
        // ...changes.services
      ]);
    },

    cancel() {
      this.$router.replace(this.value.listLocation);
    },

    finish() {
      this.$router.replace(createEpinioRoute(`c-cluster-resource-id`, {
        cluster:  this.$store.getters['clusterId'],
        resource: this.value.type,
        id:       `${ this.value.meta.namespace }/${ this.value.meta.name }`
      }));
    },

    updateSource(changes: EpinioAppSource) {
      this.source = {} as EpinioAppSource;
      const { appChart, ...cleanChanges } = changes;

      this.value.configuration = this.value.configuration || {};

      if (appChart) {
        // app chart actually belongs in config, so stick it in there
        this.set(this.value.configuration, { appchart: appChart });
      }

      this.set(this.source, cleanChanges);
    },

    updateManifestConfigurations(changes: string[]) {
      this.set(this.value.configuration, { configurations: changes });
    },
    validate(value: boolean, tab: string) {
      if (tab) {
        this.tabErrors[tab] = !value;
      }

      // UpdateSource wizard will save settings as well, needs to disable the button in case of errors
      this.steps[0].ready = value;
    }
  },
});
