<script>
import Login from '@shell/mixins/login';

export default {
  mixins: [Login],

  async fetch() {
    // Fetch the dex redirec url.
    // The dashboard would normally get this directly from the auth provider, however epinio/dex implement pkce flow
    // (additional per request validation). To support this the redirect url is per request and thus generated by the BE per login
    // Unforunatly this process also requires the state to be known up front, so for this provider we create it upfront and pass through
    // to the auth store
    const baseNonce = await this.$store.dispatch('auth/createNonce', { provider: 'epinio' });
    const encodedNonce = await this.$store.dispatch('auth/encodeNonce', baseNonce);
    const res = await this.$store.dispatch('management/request', { url: `/dex/redirectUrl?state=${ encodedNonce }` });

    const redirectAsUrl = new URL(res.redirectUrl);
    // We'll need to save this locally for when we exchange the code for a token
    const pkceCodeVerifier = redirectAsUrl.searchParams.get('code_verifier');

    // The scopes in the redirect url are pulled out and reapplied, however this does not work for dex (a decoded space separator)
    const scopes = redirectAsUrl.searchParams.get(`scope`); // This decodes it

    // redirectTo mangles the different scopes together incorrectly, and we're supply our own mangled version anyway, so nuke
    redirectAsUrl.searchParams.delete('scope');

    this.redirectOpts = {
      provider:    this.name,
      redirectUrl: redirectAsUrl.toString(),

      scopes:         scopes.split(' '), // Put it in the format expcted by the `redirectTo` action
      scopesJoinChar: ' ',

      nonce:        baseNonce,
      persistNonce: {
        ...baseNonce,
        pkceCodeVerifier
      }
    };
  },

  data() {
    return { redirectOpts: {} };
  },

  methods: {
    login() {
      this.$store.dispatch('auth/redirectTo', this.redirectOpts);
    },
  },
};
</script>

<template>
  <div class="text-center">
    <button
      ref="btn"
      class="btn bg-primary"
      style="font-size: 18px;"
      :disabled="$fetchState.pending"
      @click="login"
    >
      {{ t('epinio.login.genericProvider') }}
    </button>
  </div>
</template>
